import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

const Courts = () => {
  const { unit } = useParams(); // Obtener el UnitID desde la URL
  //const location = useLocation();
  // Desestructurar el state que es un array
  //const [unitName, unitLogo] = location.state || []; // Si el state está vacío, será un array vacío
  const [unitName, setUnitName] = useState(''); // Nuevo estado para el nombre de la unidad
  const [unitLogo, setUnitLogo] = useState(''); // Nuevo estado para el logo de la unidad
  const [courts, setCourts] = useState([]); // Estado para las canchas
  const [loading, setLoading] = useState(true); // Estado de carga
  const backendUrl = process.env.REACT_APP_BACKEND_URL; // Obtener la URL desde la variable de entorno
  const cityId = process.env.REACT_APP_CITY_ENS; // Obtener el ID de la ciudad desde el archivo .env
  const cdnAssetsUrl = process.env.REACT_APP_CDN_ASSETS_URL; // URL del CDN

  useEffect(() => {
        // Verificar si hay datos en caché
    const cachedCourts = localStorage.getItem(`courts_${unit}`);

    if (cachedCourts) {
      setCourts(JSON.parse(cachedCourts)); // Si hay caché, lo usamos
      //setLoading(false);
    }

    const fetchUnitInfo = async () => {
      try {
        const response = await fetch(`${backendUrl}/sports-units/${cityId}/${unit}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
        });
        const data = await response.json();
        if (data && data.name && data.logo) {
          setUnitName(data.name); // Asignar el nombre de la unidad
          setUnitLogo(data.logo); // Asignar el logo de la unidad
        }
      } catch (error) {
        console.error(`Error fetching unit info for ${unit}:`, error);
      }
    };

    const fetchCourts = async () => {
      try {
        const response = await fetch(`${backendUrl}/courts/unit/${unit}`, {
          method: 'GET', // Método HTTP (opcional aquí porque 'GET' es predeterminado)
          headers: {
            'Content-Type': 'application/json', // Especifica que el contenido es JSON
            'x-api-key': process.env.REACT_APP_API_KEY, // Incluye la API key
          },
        });
        const data = await response.json();

        // Si los datos del backend son diferentes a los del caché, actualizamos el estado y el caché
        if (JSON.stringify(data) !== cachedCourts) {
          setCourts(data);
          localStorage.setItem(`courts_${unit}`, JSON.stringify(data)); // Guardar en caché
        }
      } catch (error) {
        console.error(`Error fetching courts for unit ${unit}:`, error);
      } finally {
        setLoading(false);
      }
    };
    fetchUnitInfo();
    fetchCourts();
  }, [unit, backendUrl, cityId]);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader">
          <img src="/logo-no-background.png" alt="Grabala Logo" />
        </div>
      </div>
    );
  }

  return (
    <div className="container">

      <header
        style={{
          backgroundColor: '#FF0000',
          width: '100%',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <Link to="/">
          <img
            src="/logo-no-background.png"
            alt="Grábala Logo"
            style={{ paddingTop: '10px', height: '70px' }}
          />
        </Link>
      </header>

      {/* Contenido después del header */}
      <div style={{ paddingTop: '50px' }}>
        {/* Usar los valores enviados desde SportsUnits.js */}
        <h1>{unitName}</h1>
        
        {/* Mostramos el logo de la unidad deportiva */}
        <img src={`${cdnAssetsUrl}/${cityId}/${unit}/${unitLogo}`} 
        alt={`${unitName} logo`} 
        className="unit-logo" 
        style={{ maxWidth: '200px' }} />

        <h2>Selecciona una Cancha</h2>
        <div className="court-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
          {courts.map((court, index) => (
            <Link
              key={index}
              to={`/hours/${unit}/${court.SK.replace('COURT#', '')}`}
              state={{ unitName: unitName, courtName: court.name, unitStateLogo: unitLogo }}
            >
              <img
                className="court-logo"
                src={`${cdnAssetsUrl}/${cityId}/${unit}/${court.SK.replace('COURT#', '')}/${court.logo}`}
                alt={court.name}
                style={{ maxWidth: '150px', maxHeight: '150px' }}
              />
              <p>{court.name}</p>
            </Link>
          ))}
        </div>

        <Link to="/">
          <button>Volver a Clubes Deportivos</button>
        </Link>
      </div>
    </div>
  );
};

export default Courts;
